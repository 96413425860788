<template lang="pug" functional>
  svg(
    width="18"
    height="18"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg")
    path(
      d="M13.4399 2.24001H10.5599V0.320007H5.43995V2.24001H2.55995V5.76001H0.319946V10.24H2.55995V13.76H5.43995V15.68H10.5599V13.76H13.4399V10.24H15.6799V5.76001H13.4399V2.24001ZM6.07995 0.960007H9.91995V4.16001H6.07995V0.960007ZM0.959946 6.40001H4.79995V9.60001H0.959946V6.40001ZM9.91995 15.04H6.07995V11.84H9.91995V15.04ZM12.7999 13.12H10.5599V11.2H5.43995V13.12H3.19995V10.24H5.43995V5.76001H3.19995V2.88001H5.43995V4.80001H10.5599V2.88001H12.7999V5.76001H10.5599V10.24H12.7999V13.12ZM15.0399 9.60001H11.1999V6.40001H15.0399V9.60001Z"
      fill="currentColor")

</template>

<script>
export default {
  name: 'IconNodes'
}
</script>

<style>
</style>
